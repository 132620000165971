var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header d-print-none",attrs:{"app":"","clipped-left":"","elevate-on-scroll":"","color":_vm.$vuetify.theme.dark ? 'grey darken-4' : 'white'}},[(_vm.user)?_c('v-btn',{staticClass:"logo-btn d-none d-sm-flex",attrs:{"href":"https://monestro.com","target":"_blank","active-class":"no-active","outlined":""}},[_c('v-img',{attrs:{"src":require("../../assets/monestro-logo.svg"),"max-width":"9.4rem"}})],1):_c('v-btn',{staticClass:"logo-btn",attrs:{"href":"https://monestro.com","active-class":"no-active","outlined":""}},[_c('v-img',{attrs:{"src":require("../../assets/monestro-logo.svg"),"max-width":"9.4rem"}})],1),(_vm.environment === 'development')?_c('v-btn',{staticClass:"mr-4",class:_vm.$store.state.liveReloadingHack || 'desaturated',attrs:{"icon":""},on:{"click":function($event){return _vm.$store.commit('setLiveReloadingHack', !_vm.$store.state.liveReloadingHack)}}},[_c('i',{staticClass:"em em-fire"})]):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndUp && _vm.user && _vm.$route.name != 'Onboarding')?_c('v-btn',{attrs:{"fab":"","dark":"","left":"","bottom":"","absolute":""},on:{"click":function($event){return _vm.$emit('drawerToggle')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.user && _vm.accounts)?[_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"to":"/loans/primary","height":"3rem","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('header.loanMarket'))+" ")]),_c('v-fab-transition',[(_vm.$route.params.back && _vm.user && _vm.$route.name == 'Onboarding')?_c('v-btn',{staticClass:"mx-4",attrs:{"fab":"","medium":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-keyboard-return")])],1):_vm._e()],1),_c('v-spacer'),_c('BaseLocale'),(_vm.currentAccount)?_c('v-menu',{attrs:{"offset-y":"","content-class":"elevation-1","height":"3rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"user-role",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")]),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._v(" "+_vm._s(_vm.getAccountName(_vm.currentAccount))+" ")]:[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-cash")])],(_vm.$vuetify.breakpoint.lgAndUp)?_c('span',{staticClass:"grey--text ml-1"},[_vm._v(" ("+_vm._s(_vm.currentAccount.reference_number)+") ")]):_vm._e()],2)]}}],null,false,1389224758)},[_c('v-list',[_vm._l((_vm.annotatedAccounts),function(account){return _c('v-list-item',{key:account.id,class:account.id == _vm.currentAccount.id ? 'active-account' : 'not-active-account',attrs:{"link":""},on:{"click":function($event){return _vm.setAccount(account.object)}}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"bottom":"","color":account.onboarded ? 'success' : 'warning'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(account.onboarded)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle-outline")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-alert")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(account.onboarded ? _vm.$t('header.investmentAccounts.accountIsReady') : _vm.$t('header.investmentAccounts.accountIsNotReady'))}})]),_c('span',{staticClass:"ml-4",domProps:{"textContent":_vm._s(_vm.getAccountName(account))}}),_c('span',{staticClass:"grey--text"},[_vm._v(" ("+_vm._s(account.reference_number)+")")])],1)],1)}),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/account/companies/create","link":"","dense":""}},[_c('v-list-item-title',{staticClass:"text-center"},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],2)],1):_vm._e(),_c('v-menu',{attrs:{"max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"messages",attrs:{"height":"3rem","outlined":""}},'v-btn',attrs,false),on),[(_vm.unreadMessages)?_c('v-badge',{attrs:{"color":"warning","inline":"","content":_vm.unreadMessages}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-email")])],1):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-email")])],1)]}}],null,false,2767767739),model:{value:(_vm.message_menu),callback:function ($$v) {_vm.message_menu=$$v},expression:"message_menu"}},[_c('v-card',[(_vm.messages.length)?_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.messages),function(message){return _c('v-list-item',{key:message.id},[_c('v-list-item-content',{staticClass:"message-box",on:{"click":function($event){return _vm.toggleMessageStatus(message, !message.is_done)}}},[_c('v-list-item-subtitle',[_c('BaseDate',{attrs:{"date":message.created_at}})],1),_c('span',{class:{'font-weight-black': !message.is_done}},[_vm._v(_vm._s(message.message))])],1)],1)}),_c('v-divider')],2):_c('v-card-text',[_vm._v(_vm._s(_vm.$t('header.noMessages')))]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":"","to":"/account/messages"}},[_vm._v(_vm._s(_vm.$t('header.more')))])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":"","nudge-top":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"to":"/account/settings","height":"3rem","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cog")])],1)]}}],null,false,1370059681)},[_c('span',[_vm._v(_vm._s(_vm.$t('header.settings')))])]),_c('v-tooltip',{attrs:{"bottom":"","nudge-top":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-flex",attrs:{"height":"3rem","outlined":""},on:{"click":function($event){return _vm.logOut()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-logout ")])],1)]}}],null,false,2711655264)},[_c('span',[_vm._v(_vm._s(_vm.$t('header.logout')))])])]:[_c('v-btn',{staticClass:"hidden-md-and-down",attrs:{"href":"https://monestro.com/for-investors","height":"3rem","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('header.forInvestor'))+" ")]),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('BaseLocale'),_c('v-btn',{attrs:{"to":"/login","height":"3rem","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('header.login'))+" ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-menu',{attrs:{"offset-y":"","content-class":"elevation-1 header-buttons","height":"3rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-app-bar-nav-icon')],1)]}}],null,false,724464044),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.isOpen)?_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'Onboarding' },"link":""},domProps:{"textContent":_vm._s(_vm.$t('header.createFreeAccount'))}}),_c('v-list-item',{attrs:{"to":"/login","link":""}},[_vm._v(" "+_vm._s(_vm.$t('header.login'))+" ")]),_c('v-list-item',{attrs:{"href":"https://monestro.com/for-investors","link":""}},[_vm._v(" "+_vm._s(_vm.$t('header.forInvestor'))+" ")]),_c('v-list-item',[_c('v-spacer'),_c('BaseLocale'),_c('v-spacer')],1)],1):_vm._e()],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }