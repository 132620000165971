<template>
  <v-app-bar
    app
    clipped-left
    elevate-on-scroll
    class="header d-print-none"
    :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
  >
    <v-btn
      v-if="user"
      href="https://monestro.com"
      target="_blank"
      class="logo-btn d-none d-sm-flex"
      active-class="no-active"
      outlined
    >
      <v-img src="../../assets/monestro-logo.svg" max-width="9.4rem"/>
    </v-btn>
    <v-btn
      v-else
      href="https://monestro.com"
      class="logo-btn"
      active-class="no-active"
      outlined
    >
      <v-img src="../../assets/monestro-logo.svg" max-width="9.4rem"/>
    </v-btn>
    <v-btn
      v-if="environment === 'development'"
      icon
      class="mr-4"
      :class="$store.state.liveReloadingHack || 'desaturated'"
      @click="$store.commit('setLiveReloadingHack', !$store.state.liveReloadingHack)"
    >
      <i class="em em-fire"/>
    </v-btn>
    <!-- Do we even need this button? This duplicates navDrawer button -->
    <v-btn
      v-if="!$vuetify.breakpoint.mdAndUp && user && $route.name != 'Onboarding'"
      fab dark left bottom absolute
      @click="$emit('drawerToggle')"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <template v-if="user && accounts">
      <v-btn to="/loans/primary" height="3rem" outlined class="d-none d-md-flex">
        {{ $t('header.loanMarket') }}
      </v-btn>
      <v-fab-transition>
        <v-btn v-if="$route.params.back && user && $route.name == 'Onboarding'"
               class="mx-4" fab medium @click="$router.go(-1)"
        >
          <v-icon dark>mdi-keyboard-return</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer/>
      <BaseLocale/>
      <v-menu v-if="currentAccount" offset-y content-class="elevation-1" height="3rem">
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" class="user-role" outlined v-on="on">
            <!-- XXX This should probably be a <v-overflow-btn> -->
            <v-icon>mdi-chevron-down</v-icon>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              {{ getAccountName(currentAccount) }}
            </template>
            <template v-else>
              <v-icon dark>mdi-account-cash</v-icon>
            </template>
            <span v-if="$vuetify.breakpoint.lgAndUp" class="grey--text ml-1">
              ({{ currentAccount.reference_number }})
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="account in annotatedAccounts"
            :key="account.id"
            :class="account.id == currentAccount.id ? 'active-account' : 'not-active-account'"
            link
            @click="setAccount(account.object)"
          >
            <v-list-item-title>
              <v-tooltip bottom :color="account.onboarded ? 'success' : 'warning'">
                <template #activator="{ on, attrs }">
                  <v-icon v-if="account.onboarded" color="success" v-bind="attrs" v-on="on">mdi-check-circle-outline</v-icon>
                  <v-icon v-else color="warning" v-bind="attrs" v-on="on">mdi-progress-alert</v-icon>
                </template>
                <span v-text="account.onboarded ? $t('header.investmentAccounts.accountIsReady') : $t('header.investmentAccounts.accountIsNotReady')"/>
              </v-tooltip>
              <span class="ml-4" v-text="getAccountName(account)"/>
              <span class="grey--text"> ({{ account.reference_number }})</span>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item to="/account/companies/create" link dense>
            <v-list-item-title class="text-center">
              <v-icon>mdi-plus</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


      <v-menu
        v-model="message_menu"
        :max-width="400"
      >
        <template #activator="{ on, attrs }">
          <v-btn height="3rem" outlined class="messages" v-bind="attrs" v-on="on">
            <v-badge v-if="unreadMessages" color="warning" inline :content="unreadMessages">
              <v-icon dark>mdi-email</v-icon>
            </v-badge>
            <v-icon v-else dark>mdi-email</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list v-if="messages.length" two-line>
            <v-list-item v-for="message in messages" :key="message.id">
              <v-list-item-content class="message-box" @click="toggleMessageStatus(message, !message.is_done)">
                <v-list-item-subtitle>
                  <BaseDate :date="message.created_at"/>
                </v-list-item-subtitle>
                <span :class="{'font-weight-black': !message.is_done}">{{ message.message }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
          </v-list>
          <v-card-text v-else>{{ $t('header.noMessages') }}</v-card-text>

          <v-card-actions>
            <v-btn text block to="/account/messages">{{ $t('header.more') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-tooltip bottom nudge-top="8">
        <template #activator="{ on, attrs }">
          <v-btn to="/account/settings" class="d-none d-md-flex" height="3rem" outlined v-bind="attrs" v-on="on">
            <v-icon dark>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('header.settings') }}</span>
      </v-tooltip>

      <v-tooltip bottom nudge-top="8">
        <template #activator="{ on, attrs }">
          <v-btn height="3rem" outlined class="d-none d-md-flex" v-bind="attrs" v-on="on" @click="logOut()">
            <v-icon dark>
              mdi-logout
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('header.logout') }}</span>
      </v-tooltip>
    </template>
    <template v-else>
      <v-btn class="hidden-md-and-down" href="https://monestro.com/for-investors" height="3rem" outlined>
        {{ $t('header.forInvestor') }}
      </v-btn>
      <v-spacer/>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <BaseLocale/>
        <v-btn to="/login" height="3rem" outlined>
          {{ $t('header.login') }}
        </v-btn>
<!--        <v-btn :to="{ name: 'Onboarding' }" height="3rem" outlined>-->
<!--          {{ $t('header.createFreeAccount') }}-->
<!--        </v-btn>-->
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-menu v-model="isOpen" offset-y content-class="elevation-1 header-buttons" height="3rem">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-app-bar-nav-icon/>
            </v-btn>
          </template>
          <v-list v-if="isOpen">
            <v-list-item :to="{ name: 'Onboarding' }" link v-text="$t('header.createFreeAccount')"/>
            <v-list-item to="/login" link>
              {{ $t('header.login') }}
            </v-list-item>
            <v-list-item href="https://monestro.com/for-investors" link>
              {{ $t('header.forInvestor') }}
            </v-list-item>
            <v-list-item>
              <v-spacer/>
              <BaseLocale/>
              <v-spacer/>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { VUE_APP_ENVIRONMENT } from "@/config"
import { EventBus } from '@/wsupdate/eventbus'

import Vue from 'vue'

export default Vue.extend({
  name: "Header",
  emits: ["drawerToggle"],
  data() {
    return {
      message_menu: false,
      isOpen: false,
      messages: [],
    }
  },
  computed: {
    accounts() {
      return this.user.investment_accounts
    },
    environment() {
      return VUE_APP_ENVIRONMENT
    },
    user: function() {
      return this.$auth.user()
    },
    currentAccount: function() {
      return this.$store.state.account
    },
    unreadMessages: function() {
      return this.messages.filter(m => !m.is_done).length
    },
    annotatedAccounts() {
      return this.accounts.map(account => ({
        ...account,
        object: account,
        onboarded: this.$auth.user().verification === 'approved' && account.is_approved,
      }))
    },
  },
  watch: {
    user:  {
      immediate: true,
      handler(value, oldValue) {
        if (this.user && value?.id != oldValue?.id) {
          // TODO this is probably too eager and will do too many requests
          this.fetchMessages()
        }
      },
    },
    "$i18n.locale": {
      handler() {
        this.isOpen = false
      },
    },
  },
  mounted() {
    EventBus.$on( 'update-messages', this.fetchMessages)
  },
  unmounted() {
    EventBus.$off('update-messages', this.fetchMessages)
  },
  methods: {
    toggleMessageStatus(reminder, is_done) {
      this.$http
        .patch(`/reminders/${reminder.id}/`, { is_done })
        .then(() => {
          this.fetchMessages()
        })
    },
    fetchMessages() {
      this.$http
        .get(`/reminders/?size=5`)
        .then((response) => {
          this.messages = response.data.results
        })
    },
    setAccount(account) {
      this.$http.patch(`/auth/users/me/`, { last_selected_investment_account: account.id })
      this.$store.commit("setCurrentInvestmentAccount", account)
      this.isOpen = false
    },
    getAccountName(investmentAccount) {
      if (investmentAccount.type === "private") {
        return `${this.$auth.user().first_name} ${this.$auth.user().last_name}`
      } else {
        return investmentAccount.company && investmentAccount.company.name || this.$t('header.company')
      }
    },
    logOut() {
      if (this.$auth.impersonating()) {
        this.$auth.unimpersonate({
          makeRequest: false,
          redirect: { name: "Dashboard" },
        })
          .then(() => {
            this.$router.go("/")
          })
      } else {
        this.$auth.logout()
        if (!this.$store.state.account) { // it is supposed to not exist for LO
          this.$router.go("/")
        }
        this.$store.commit("setCurrentInvestmentAccount", {})
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.desaturated {
  filter: saturate(0%);
}
</style>

<style lang="scss">
.message-box{
  cursor: pointer;
}
.header .v-btn--outlined {
  &.v-btn {
    background-color: transparent !important;
    border: 0 !important;
    margin: 0 0.1rem;
  }

  &.v-btn::before {
    background-color: transparent !important;
  }

  &.v-btn--active::before {
    background-color: transparent !important;
    border: 2px solid !important;
    opacity: 0.8 !important;
  }

  &.theme--dark.v-btn--active::before {
    border-color: #EEE !important;
  }

  &.v-btn--active.no-active::before {
    opacity: 0 !important;
  }
}

.header-buttons .v-btn {
  border: 0 !important;
}

.header .logo-btn::before {
  background-color: transparent !important;
}

header.theme--dark .logo-btn {
  filter: invert(1);
}

.active-account {
  border: 2px solid !important;
}
.theme--dark.active-account {
  border-color: #EEE !important;
}
.not-active-account {
  border: 2px solid transparent !important;
}
#crisp-chatbox {
  display:none !important;
}
.crisp-client {
  display: none !important;
}
</style>
